const profileTabs = () => {
    return {
        tabs: [],
        currentIndex: -1,
        init() {
            this.tabs = document.querySelectorAll(".profileTabs__tabs a");
            this.tabs.forEach((item, index) => {
                item.tabIndex = 0; // Set tabIndex for each tab
                item.addEventListener("focus", () => {
                    this.currentIndex = index;
                });
            });
            const url = new URL(window.location.href);
            let hash = url.hash.substring(1);
            if (hash != "" && hash != null) {
                this.anchorProfile(this.tabs, hash);
            }
        },
        nextTabFocus() {
            this.currentIndex = (this.currentIndex + 1) % this.tabs.length;
            this.tabs[this.currentIndex].focus();
        },
        prevTabFocus() {
            this.currentIndex = (this.currentIndex - 1 + this.tabs.length) % this.tabs.length;
            this.tabs[this.currentIndex].focus();
        },
        anchorProfile(tabs, hash) {
            tabs.forEach(item => {
                if (hash == item.id) {
                    this.currentTab = parseInt(item.dataset.id);
                }
            });
        }
    };
};

export default profileTabs;
