function findSpecialtyTeam() {
    let indication = document.querySelector("input[name='check']:checked").id;
    let specialist = document.querySelector(".specialty .option.selected").innerText;
    switch (indication) {
        case "atopicdermatitis":
            switch (specialist) {
                case "Allergist":
                    return "ALLERGIST";
                case "Dermatologist":
                case "Pediatrician":
                case "Primary Care Physician/Internist":
                    return "DERM";
                default:
                    return "Direct to FFEs and FDMs (See details)";
            }
        case "eoe":
            switch (specialist) {
                case "Allergist":
                    return "ALLERGIST";
                case "Gastroenterologist":
                case "Pediatrician":
                case "Primary Care Physician/Internist":
                    return "GASTRO";
                default:
                    return "Direct to FFEs and FDMs (See details)";
            }
        case "asthma":
        case "crswnp":
            switch (specialist) {
                case "Pulmonary Rehabilitation Therapist":
                case "Pulmonologist":
                    return "PULM";
                default:
                    return "Allergist/ENT (A/E)";
            }
        case "prurigo-nodularis":
            return "DERM";
        case "csu":
            switch (specialist) {
                case "Allergist":
                    return "ALLERGIST";
                default:
                    return "DERM";
            }
        case "copd":
            return "PULM";
        default:
            return "error";
    }
}

export default findSpecialtyTeam;
